#tooltip-top {
    cursor: pointer;

    .tooltip-arrow {
        display: none;
    }

    .tooltip-inner {
        color: $white;
        font-size: 12px;
        font-weight: 500;
        padding: 8px 12px;
        border-radius: $border-radius-lg;
        background: #202224;
    }
}
