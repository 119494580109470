//
// tables.scss
//

.dataTables_paginate {
    display: none;
}

.paginate_button {
    display: none;
}
.dataTables_info {
    display: none;
}
.dataTables_length {
    display: none;
}

//Table centered (Custom)
.table-centered {
    td,
    th {
        vertical-align: middle !important;
    }
}

// Custom table components (Custom)
.table {
    th {
        font-weight: 600;
    }
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    border-collapse: collapse;
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

// custom-table
.custom-table {
    .select-col {
        width: 100px;
    }
}

.t-sticky-1 {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
}

.t-sticky-2 {
    position: -webkit-sticky;
    position: sticky;
    left: 44.6px;
    z-index: 1;
    min-width: 65px;
}

.t-sticky-3 {
    position: -webkit-sticky;
    position: sticky;
    left: 100px;
    z-index: 1;
}

.t-sticky-4 {
    position: sticky;
    left: 40.6px;
    z-index: 1;
    min-width: 64px;
}

.v-align {
    vertical-align: middle;
}

.tableFixHead {
    overflow-y: auto;
    height: 200px;
}

.tableFixHead table {
    border-collapse: collapse;
    width: 100%;
}

.tableFixHead th {
    position: sticky;
    top: 0;
    background: #eee;
}

@media (min-width: 992px) {
    .navbar-nav .dropdown-menu {
        left: 50%;
        min-width: calc(10rem + 3rem);
        padding: 0.75rem 0;
        box-shadow: 0 0.8rem 1.2rem rgb(52 58 64 / 8%);
        visibility: hidden;
        opacity: 0;
        transform: translate3d(-50%, 30px, 0);
        transition: all 0.3s ease-in-out;
        transition-property: opacity, visibility, transform;
        transform-origin: top center;
    }
}

.table > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    border-bottom-width: 0px;
}

.sticky-h-1 {
    position: sticky;
    top: -1px;
    z-index: 2;
}

.sticky-h-2 {
    position: sticky;
    top: 41px;
    z-index: 2;
}

.sticky-h-3 {
    position: sticky;
    top: 59px;
    z-index: 2;
}
.sticky-h-4 {
    position: sticky;
    top: 84px;
    z-index: 2;
}

.th-b-color {
    border: 1px solid #696969;
}

.td-bottom-none {
    border-bottom: none !important;
}

td {
    border-right: 1px solid #696969;
    border-bottom: none;
}
th {
    border: 1px solid #696969;
}

.div-th {
    width: initial;
    border-bottom: 0.5px solid #696969;
    position: relative;
    top: 21px;
}

.div-th-3 {
    width: initial;
    border-bottom: 0.5px solid #696969;
    position: relative;
    top: 29px;
}

.div-th-2 {
    width: initial;
    border-bottom: 0.5px solid #696969;
    position: relative;
    top: 20px;
}

.p0 {
    padding: 0 !important;
}
.bg-table-1 {
    background-color: #e9ebef;
}

.table-grey {
    --bs-table-bg: #dfe6eb;
    --bs-table-striped-bg: #dfe6eb;
    --bs-table-striped-color: #dfe6eb;
    --bs-table-active-bg: #dfe6eb;
    --bs-table-active-color: #dfe6eb;
    --bs-table-hover-bg: #dfe6eb;
    --bs-table-hover-color: #dfe6eb;
    color: black;
    border-color: #484e53;

    thead tr th {
        vertical-align: middle;
        font-size: 12px;
        text-align: center;
    }
}

.table-grey-2 {
    width: 100%;
    border: 1px solid #858687;

    thead,
    tfoot {
        tr th {
            background: #ccd9e2;
            text-align: center;
        }
    }

    tr th,
    tr td {
        font-size: 11.5px;
        vertical-align: middle;
        padding: 0.4rem 0.5rem;
    }

    thead tr {
        box-shadow: 0px 1px 0px 0px #858687;
    }

    tbody tr:nth-child(even) {
        background-color: #dfe6eb;
    }

    tbody tr:nth-child(odd) {
        background-color: #f4f5f7;
    }
}

.table-head {
    td,
    th {
        background: #ccd9e2 !important;
        text-align: center;
        font-weight: bold !important;
        border: 1px solid #858687;
    }
}

.bottom-line {
    box-shadow: 0px 1px 0px 0px #858687;
}

.sticky-border-y-gray {
    -webkit-box-shadow: 0px -1px 0px 0px #dee2e6, 0px 1px 0px 0px #dee2e6;
    box-shadow: 0px -1px 0px 0px #dee2e6, 0px 1px 0px 0px #dee2e6;
}

.table-border-outline {
    outline: 1px solid #484e53 !important;
    border: none;
    outline-offset: -1px;
}

.table-border-outline-right {
    box-shadow: -5px 0px 0px 0px black, 5px 0px 0px 0px black;
    border: none;
    outline-offset: -1px;
}

.b1234 {
    border-width: 1px;
    border-left: 1px solid rgb(204, 204, 204);
    border-top: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
}

.formheader {
    background: #efefef;
    border: 1px solid #03476f;
    padding: 5px;
}

//radar

.body-radar {
    background-color: #e5e5e5;
    height: 100vh;
}

.box-radar {
    max-width: 900px;
    width: 100%;
    background-color: #e5e5e5;
    border-radius: 10px;
}

.box-user {
    max-width: 284px;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
}

.usr-icon {
    width: 29px;
    height: 29px;
    border-radius: 50%;
}

.icon-radar {
    position: absolute;
    right: 0;
}

.txt-radar1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #1d1d1d;
}

.txt-radar2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    color: #1d1d1d;
}

.btn-radar-close {
    width: 153px;
    padding: 10px;
    border: 1px solid #f0f0f0f0;
    border-radius: 6px;
}

.width-radar-modal {
    width: 401px !important;
}

.box-desc-radar {
    width: 321px;
    background: #e6fcff;
    border: 1px solid #8ed5de;
    border-radius: 6px;
    padding: 10px;
    position: relative;
}

.txt-edit-radar {
    color: #0f98d8;
    font-weight: bold;
    font-size: 10px;
    z-index: 68;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.btn-radar-save {
    width: 153px;
    padding: 10px;
    border: 1px solid #0b7f8e;
    border-radius: 6px;
    background: #0b7f8e;
    color: white;
}

.btn-batas-hari {
    width: 80px;
    padding: 10px;
    border: 1px solid #0b7f8e;
    border-radius: 6px;
    background: #0b7f8e;
    color: white;
}

.box-table-radar {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    // border: 1px solid #f8f8f8 !important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;

    &.user-role {
        thead {
            tr {
                th {
                    border-top: none;
                    border-bottom: 1px solid #e3e5e8;
                    color: #51555a;
                    font-weight: 500;
                }
            }
        }
        tbody {
            tr {
                .disabled {
                    background: #f4f5f6;
                }
                .edit-peran {
                    padding: 16px 22px;
                }
            }
        }
    }
}

.fl-table td,
.fl-table th {
    padding: 8px;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
}

.bg-transparent {
    background: transparent !important;
}

.bg-white {
    background: white !important;
}

.fl-table td {
    // border-bottom: 1px solid #d8d8d8;
    font-size: 12px;
    padding: 12px 0;
}

.fl-table thead th {
    color: black;
    background: rgba(11, 127, 142, 0.1);
}

.tr-radar {
    &:hover {
        background: rgb(235, 235, 235);
    }
}

.color-green {
    color: #0b7f8e;
}
.color-green-light {
    color: #2eb85c;
}
.color-red-light {
    color: #e91a26;
}
.t-green {
    color: #86efac;
}
.t-yellow {
    color: #fde047;
}
.t-red {
    color: #f87171;
}

.color-black-sensor {
    color: #000000;
}

.color-green-light-sensor {
    color: #26ff31;
}

.color-blue-sensor {
    color: #0b7f8e;
}

.color-yellow {
    color: #f78216;
}

.color-red {
    color: red;
}

.box-jml-ayam {
    background: white;
    padding: 15px;
    border-radius: 4px;
    height: 46px;
    position: absolute;
    right: 0;
    bottom: 15px;
}

.dataTables_scrollBody {
    max-height: 90vh !important;
}

/* Define the classes to be used in the table datatables stripe */
.stripe-1 {
    background-color: #f4f5f7 !important;
}

.stripe-2 {
    background-color: #dfe6eb !important;
}

.tdLeft {
    border: none !important;
}

.tdRight {
    border: none !important;
}

.tblChart td {
    border: none !important;
}

/* Responsive */

@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }
    .table-wrapper:before {
        content: 'Scroll horizontally >';
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }
    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }
    .fl-table thead th:last-child {
        border-bottom: none;
    }
    .fl-table thead {
        float: left;
    }
    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }
    .fl-table td,
    .fl-table th {
        padding: 20px 0.625em 0.625em 0.625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }
    .fl-table tbody tr {
        display: table-cell;
    }
    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }
    .fl-table tr:nth-child(even) {
        background: transparent;
    }
    .fl-table tr td:nth-child(odd) {
        background: #f8f8f8;
        border-bottom: 1px solid #bdb8b8;
    }
    .fl-table tr td:nth-child(even) {
        border-bottom: 1px solid #bdb8b8;
    }
    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}

.details {
    display: none;
}

.details.collapsed {
    display: table-row;
}

.container-box {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.custom-table-stripped {
    font-size: 12px;

    tr td,
    tr th {
        border: none;
        padding: 10px;
    }

    thead tr {
        background-color: white;
    }

    tbody tr:nth-child(odd) {
        background-color: var(--bs-table-striped-bg);
    }
    tbody tr:nth-child(even) {
        background-color: white;
    }
}

.custom-table-stripped-2 {
    font-size: 12px;

    tr td,
    tr th {
        border: none;
        padding: 10px;
    }

    thead tr {
        background-color: white;
    }

    tbody tr:nth-child(odd) {
        background-color: white;
    }
    tbody tr:nth-child(even) {
        background-color: var(--bs-table-striped-bg);
    }
}

.custom-table {
    font-size: 12px;

    tr td,
    tr th {
        border: none;
        padding: 12px;
    }

    thead tr {
        background-color: white;
    }

    tr {
        border-bottom: 1px solid #ebebeb;
    }
}

.custom-table-plain {
    font-size: 12px;

    tr td,
    tr th {
        border: none;
        padding: 12px;
    }

    thead tr {
        background-color: transparent;
    }

    tr {
        border-bottom: none;
    }
}

.custom-table-plain.compact {
    tr td,
    tr th {
        padding: 6px 8px;
    }
}

.custom-table.zonasi-harga-table {
    border-spacing: 0px 0.5rem;
    border-collapse: separate;

    tr td {
        background: #f3f4f7;
    }

    td,
    th {
        vertical-align: middle;
        text-align: center;
    }
}

.section-table-zonasi-harga {
    width: calc(fit-content * 2);
}

.table-zonasi-harga {
    tr:first-of-type th:first-of-type {
        border-top-left-radius: 8px;
    }
    tr:first-of-type th:last-of-type {
        border-top-right-radius: 8px;
    }
    tr:last-of-type td:first-of-type {
        border-bottom-left-radius: 8px;
    }
    tr:last-of-type td:last-of-type {
        border-bottom-right-radius: 8px;
    }

    tr {
        border: none;
    }

    tr th {
        min-width: 70px;
    }

    tr td,
    tr th {
        padding: 6px 8px;
        border: 2px white solid;
        // background-color: #ffe5e8;
        text-align: center;
        vertical-align: middle;
        // border-radius: 5px !important;
        text-wrap: nowrap;
    }

    &.table-tanggal {
        tr th {
            background-color: #dfe6eb;
        }

        tr td {
            background-color: #f4f5f7;
        }
    }
}

.table-evaluasi-harga {
    border-collapse: separate;

    tr th {
        background-color: #dfe6eb;
        height: 45px;
        white-space: nowrap;
    }

    tr td,
    tr th {
        vertical-align: middle;
        text-align: center;
    }

    tr td {
        text-wrap: nowrap;
        border: 1px solid #f4f5f7;
    }

    &.table-tanggal {
        &.show-by-zona {
            tr th {
                height: 92px;
            }
        }

        &.show-by-unit {
            tr th {
                height: 134px;
            }
        }

        tr td {
            background-color: #f4f5f7;
        }
    }
}

.table-laporan {
    font-size: 13px;
    table {
        width: 100%;
    }

    tr td,
    tr th {
        border: solid 1px #d3d4d6;
        padding: 12px;
    }

    tr {
        th {
            background-color: #f3f4f7;
            text-align: center;
        }
    }

    &.table-laporan-primary {
        tr th {
            background-color: #d0e6e8;
        }
    }

    &.striped tbody tr:nth-child(even) {
        background-color: #fbfbfc;
    }
    &.striped tbody tr:nth-child(odd) {
        background-color: white;
    }

    .row-odd {
        background-color: #fbfbfc;
    }

    .row-even {
        background-color: white;
    }

    thead {
        th {
            position: sticky;
            top: 0;
        }

        th::after,
        th::before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
        }

        th::before {
            top: -1px;
            border-top: 1px solid #d3d4d6;
        }

        th::after {
            bottom: -1px;
            border-bottom: 1px solid #d3d4d6;
        }
    }

    tfoot {
        position: relative !important;
    }
}

.table-panduan-panen {
    margin-bottom: 8px;

    tr th,
    tr td {
        border: 1px solid #d0d5da;
        height: 32px;
        color: #555555;
        font-weight: 500;
        vertical-align: middle;
    }

    tr td {
        height: 48px;
    }
}

.table-list-ppic {
    font-size: 12px;

    &.kirim-pakan {
        thead {
            tr {
                background: #f4f5f6 !important;
                border-bottom: 1px solid #e3e5e8;
            }
        }
        tbody {
            tr {
                background: #fff !important;
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    &.stok-pakan {
        thead {
            tr {
                th {
                    &:first-child {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }
                    &:last-child {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }
        }
    }

    tr td,
    tr th {
        border: none;
        padding: 10px;
    }

    tr th {
        color: #6c7279;
        font-weight: 500;
    }

    tr td {
        color: #202224;
        font-weight: 400;
        border-bottom: 1px solid #e0e3e6;
    }

    thead tr {
        background-color: #eceef0;
    }

    &.custom-table-ppic {
        tr {
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

.table-pakan {
    margin-bottom: 0;
    thead {
        tr {
            background: #eceef0 !important;
            th {
                color: #51555a;
                font-weight: 500;
            }
        }
    }
    tbody {
        tr {
            &:last-child {
                border-bottom: none;
            }
            td {
                color: #202224;
                font-weight: 500;
                .form-check-input {
                    width: 12px;
                    height: 12px;
                    border: 1.5px solid #a2abb5;
                    transform: scale(1.5) !important;
                    box-shadow: none;
                    &:checked {
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }
        .disabled {
            background: #eceef0;
            color: #51555a;
            td {
                color: #51555a;
            }
        }
    }
}

.table-management-dashboard {
    thead {
        tr {
            th {
                color: #6c7279;
                font-weight: 400;
                font-size: 12px;
                padding: 6px 8px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                color: #202224;
                font-weight: 400;
                font-size: 12px;
                padding: 6px 8px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
            &:last-child {
                td {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.table-realisasi-terakhir {
    thead {
        tr {
            th {
                background: #b9ecca;
            }
        }
    }
    tbody {
        tr {
            td {
                background: #ebfaf0;
            }
        }
    }
}

.no-scrollbar {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .form-control {
        border: 1px solid #e3e5e8;
        border-radius: 8px;
        padding: 8px 12px 8px 35px;
        background: url('../../images/icons/search.svg') no-repeat white;
        background-position: 12px center;
    }

    .form-select {
        max-width: 40%;
        padding: 9px 16px;
        border-radius: 8px;
    }

    .table-assessment {
        font-size: 12px;

        tr td,
        tr th {
            border: none;
        }

        tr th {
            height: 63px;
            color: #202224;
            font-weight: 500;
            padding: 10px 8px;
        }

        tr td {
            color: #51555a;
            font-weight: 400;
            padding: 17px 8px;
            background: #fff;
            border-top: 1px solid #e3e5e8;
        }

        thead tr {
            background-color: #f4f5f6;
        }

        tr {
            .title {
                font-size: 14px;
                font-weight: 500;
                color: #202224;
            }
        }
    }
}
