//
// modal.scss
//

.modal {
    .modal-content {
        .modal-body {
            padding: 0 $modal-inner-padding $modal-inner-padding $modal-inner-padding;
        }
    }
}

// Title text within header
.modal-title {
    margin-top: 0;
}

// Full modal
.modal-full {
    width: 92%;
    max-width: none;
}

// Custom animation modal
.modal-demo {
    background-color: $white;
    width: 600px !important;
    border-radius: 4px;
    display: none;
    position: relative;
    .close {
        position: absolute;
        top: 15px;
        right: 25px;
        color: $light;
    }
}

@media (max-width: 768px) {
    .modal-demo {
        width: 96% !important;
    }
}

.custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    background-color: $dark;
    color: $white;
    text-align: left;
    margin: 0;
}

.custom-modal-text {
    padding: 20px;
}

.custombox-modal-wrapper {
    text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
    .close {
        top: 20px;
        z-index: 9999;
    }
}

.modal-pakan {
    .modal-content {
        border: none;
        border-radius: 8px;
    }
    .modal-footer {
        border: none;
        display: block;
        padding: 24px 16px;
        box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.2);
        .btn-disabled {
            border: none !important;
            background: #aed4d9 !important;
            color: #fff !important;
        }
    }
}

.modal-rincian-produk {
    &.kirim-pakan {
        .modal-content {
            .modal-header {
                .modal-title {
                    display: flex;
                    align-items: center;
                    .form-select {
                        padding: 0;
                        border: none;
                        color: #202224;
                        font-size: 20px;
                        font-weight: 600;
                        appearance: auto;
                        background-image: none;
                        cursor: pointer;
                        &:focus {
                            box-shadow: none;
                        }
                        option {
                            font-size: 12px;
                        }

                        &.ews {
                            width: 100%;
                            max-width: 12%;
                            font-size: 12px;
                            font-weight: 400;
                            border: 1px solid #e3e5e8;
                            padding: 8px 12px;
                            background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
                            appearance: none;
                            background-position: right 10px center;
                        }
                    }
                }
            }
            .modal-body {
                .segment {
                    .rts___tabs___container {
                        border: 1px solid #e3e5e8;
                        background: #f4f5f6;
                        border-radius: 6px;
                        .rts___tabs {
                            justify-content: space-between;
                            width: 100%;
                            .rts___btn {
                                width: 100%;
                                color: #202224;
                                &.rts___tab___selected {
                                    box-shadow: 0 1px 4px 0 rgba(12, 12, 13, 0.05) !important;
                                    border-radius: 4px !important;
                                    background: #fff !important;
                                    border: none !important;
                                    color: #202224 !important;
                                }
                            }
                        }
                    }
                }
                .rts___tabs___container {
                    border-bottom: 1px solid #e3e5e8;
                    padding: 0;
                    .rts___tabs {
                        border-radius: 0 !important;
                        border: none !important;
                        .rts___btn {
                            color: #51555a !important;
                            background: none !important;
                            box-shadow: none;
                            padding: 8px 12px;
                            font-weight: 500 !important;
                            font-size: 12px;
                            &.rts___tab___selected {
                                color: #0b7f8e !important;
                                border-bottom: 1px solid #0b7f8e !important;
                                border-radius: 0;
                            }
                        }
                    }
                }
                .accordion-wrapper {
                    height: 600px;
                    overflow-y: auto;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    .accordion {
                        border: 1px solid #d0d5da;
                        border-radius: 8px;
                        background: #f4f5f6;
                        padding: 8px 12px;
                    }
                }
                .form-control {
                    border: 1px solid #e3e5e8;
                    border-radius: 6px;
                    padding: 8px 12px 8px 35px;
                    background: url('../../images/icons/search.svg') no-repeat;
                    background-position: 12px center;
                }
                &.chickin {
                    .rts___tabs___container {
                        &:nth-child(2) {
                            border: none !important;
                            .rts___tabs {
                                .rts___btn {
                                    transition: all 0.3s ease-in-out;
                                    &:hover {
                                        color: #fff !important;
                                        background: #0b7f8e !important;
                                        border: 1px solid #0b7f8e !important;
                                    }
                                }
                                .rts___tab___selected {
                                    color: #fff !important;
                                    background: #0b7f8e !important;
                                    border-bottom: none !important;
                                }
                            }
                        }
                    }
                }
                &.stok-panen {
                    .tab-segmentasi {
                        .rts___tabs___container {
                            &:nth-child(2) {
                                border: none !important;
                                .rts___tabs {
                                    .rts___btn {
                                        transition: all 0.3s ease-in-out;
                                        &:hover {
                                            color: #fff !important;
                                            background: #0b7f8e !important;
                                            border: 1px solid #0b7f8e !important;
                                        }
                                    }
                                    .rts___tab___selected {
                                        color: #fff !important;
                                        background: #0b7f8e !important;
                                        border-bottom: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .date-wrapper {
                    position: relative;
                    width: 10%;
                    .date {
                        padding: 9px 32px;
                        text-align: center;
                        background-position: right 12px center;
                        background: none;
                    }
                    .calendar-icon {
                        position: absolute;
                        top: 20px;
                        left: 12px;
                        transform: translateY(-50%);
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .modal-content {
        border: none;
        border-radius: 8px;
        .modal-header {
            padding: 24px 24px 0 24px;
        }
        .modal-body {
            padding: 0 24px 24px 24px;
        }
    }
}

.modal-peran {
    .modal-content {
        border: none;
        border-radius: 8px;
        overflow: hidden;
    }
}
