#dashboard {
    .select-ap {
        padding: 10px 12px;
        border-radius: 8px;
        color: #202224;
        font-weight: 400;
        font-size: 14px;
        border: 1px solid #e3e5e8;
        width: 100%;
        cursor: pointer;
    }
    .pakan {
        .form-select {
            padding: 0;
            border: none;
            color: #202224;
            font-weight: 500;
            appearance: auto;
            background-image: none;
            cursor: pointer;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .form-control {
        border: 1px solid #e3e5e8;
        border-radius: 6px;
        max-width: 50%;
        padding: 8px 12px 8px 35px;
        background: url('../../../images/icons/search.svg') no-repeat;
        background-position: 12px center;
    }
    .custom-tab {
        transition: all 0.3s ease-in-out;
        border-bottom: 2px solid transparent !important;
        &:hover {
            border-bottom: 2px solid #0b7f8e !important;
        }
        &.active {
            border-bottom: 2px solid #0b7f8e !important;
        }
    }
    .recharts-bar-rectangle,
    .recharts-area {
        cursor: pointer;
    }
}

.custom-spinner {
    width: 30px;
    height: 30px;
    border: 5px solid #e3e5e8;
    border-top: 5px solid #0b7f8e;
    border-radius: 50%;
    animation: spiner 1s linear infinite;
}

@keyframes spiner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Small mobile */
@media (min-width: 320px) and (max-width: 374px) {
    #dashboard {
        .w-25 {
            width: 100% !important;
        }
    }

    .modal-rincian-produk {
        &.kirim-pakan {
            .modal-content {
                .modal-header {
                    .modal-title {
                        display: block !important;
                        .div {
                            display: none;
                        }
                        .form-select {
                            &.ews {
                                max-width: 50%;
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Medium mobile */
@media (min-width: 375px) and (max-width: 424px) {
    #dashboard {
        .w-25 {
            width: 100% !important;
        }
    }

    .modal-rincian-produk {
        &.kirim-pakan {
            .modal-content {
                .modal-header {
                    .modal-title {
                        display: block !important;
                        .div {
                            display: none;
                        }
                        .form-select {
                            &.ews {
                                max-width: 50%;
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Large mobile */
@media (min-width: 425px) and (max-width: 599px) {
    #dashboard {
        .w-25 {
            width: 100% !important;
        }
    }

    .modal-rincian-produk {
        &.kirim-pakan {
            .modal-content {
                .modal-header {
                    .modal-title {
                        display: block !important;
                        .div {
                            display: none;
                        }
                        .form-select {
                            &.ews {
                                max-width: 50%;
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Small tablet */
@media (min-width: 600px) and (max-width: 767px) {
    #dashboard {
        .w-25 {
            width: 100% !important;
        }
    }

    .modal-rincian-produk {
        &.kirim-pakan {
            .modal-content {
                .modal-header {
                    .modal-title {
                        .form-select {
                            &.ews {
                                max-width: 28%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Medium tablet */
@media (min-width: 768px) and (max-width: 1023px) {
    .modal-rincian-produk {
        &.kirim-pakan {
            .modal-content {
                .modal-header {
                    .modal-title {
                        .form-select {
                            &.ews {
                                max-width: 28%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Large tablet */
@media (min-width: 1024px) and (max-width: 1279px) {
    #dashboard {
        .w-25 {
            width: 100% !important;
        }
    }

    .modal-rincian-produk {
        &.kirim-pakan {
            .modal-content {
                .modal-header {
                    .modal-title {
                        .form-select {
                            &.ews {
                                max-width: 16%;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Small laptop */
@media (min-width: 1280px) and (max-width: 1439px) {
    #dashboard {
        .w-25 {
            width: 100% !important;
        }
    }
}

/* Medium laptop */
@media (min-width: 1440px) and (max-width: 1599px) {
}

/* Large laptop */
@media (min-width: 1600px) and (max-width: 1919px) {
}

/* Small desktop */
@media (min-width: 1920px) and (max-width: 2559px) {
}

/* Large desktop */
@media (min-width: 2560px) and (max-width: 3439px) {
}

/* Ultra-Wide screens */
@media (min-width: 3440px) and (max-width: 3839px) {
}

/* 4K screens */
@media (min-width: 3840px) {
}
